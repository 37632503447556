<template>
    <el-dialog style="z-index: 111111;border-radius: 10px;"
        title="Welcome back"
        :visible.sync="isShow"
        width="480px"
        modal-append-to-body
        append-to-body
        center
        :close-on-click-modal="false"
        @close="onClose">
            <div slot="title">
                <div class="title">
                    {{ title }}
                </div>
                <div class="tip mt-1">
                    Notes: If you can't receive the email, please  <a class="ctus" href="/contact-us">contact support</a>
                </div>
            </div>
        <el-form label-position="top" ref="ruleForm" :model="params" :rules="rules">
            <el-form-item label="" prop="email">
                <template #label>
                    <div class="d-flex space-between">
                        <span>User E-mail <span class="required">*</span></span>
                    </div>
                </template>
                <el-input size="small" class="w-full" v-model="params.email"></el-input>
            </el-form-item>
            <el-form-item class="w-full" style="margin-bottom: 0">
                <div class="d-flex align-center">
                    <div style="width: 200px">Security Code<span class="required">*</span></div>
                    <el-input size="small" class="mr-1" style="width: 150px" v-model="params.captcha"></el-input>
                    <img class="mr-1 capt-image" :src="captImage" />
                    <el-button class="refresh-btn" @click="getCaptcha">Refresh</el-button>
                </div>
            </el-form-item>
            <el-button class="mt-3 w-full subbtn" style="" @click.native.prevent="onSubmit">
                {{ title == 'Forgot Your Password?' ? 'Send Reset email' : 'Resend activation email'}}
            </el-button>
            <div class="bottom-desc">
                <a class="register" @click="onClose">Back to login</a>
            </div>
        </el-form>
    </el-dialog>
</template>
<script>
/** api */
import { goLogin } from '@/assets/api/oauth'

import { getCaptcha, forgetPassword, activationLink } from '@/assets/api/user'

export default {
    data: () => ({
        title: '',
        isShow: false,
        params: {
            email: '',
            key: '',
            captcha: ''
        },
        captcha: '',//验证码
        formLabelWidth: '200px',
        rules: {},
        passwordType: "password",
        capsTooltip: false,
        captImage: null,//验证码图片
    }),
    mounted() {
        this.rules = {
            email: [
                { required: true, message: '请输入Email', trigger: 'blur' },
            ]
        }
    },
    methods: {
        onShow(title) {
            this.title = title
            this.isShow = true
            this.getCaptcha()
        },
        // 获取验证码
        getCaptcha() {
            getCaptcha().then(res => {
                this.params.key = res.data.key
                this.captImage = res.data.img
            })
        },
        onSubmit() {
            this.$refs["ruleForm"].validate((valid) => {
                if (valid) {
                    this.$loading()
                    if (this.title == 'Forgot Your Password?') {
                        forgetPassword(this.params).then(res => {
                            this.$loading().close()
                            this.$message.success('Submission successful, please check the email！')
                            this.onClose()
                        }, err => {
                            this.$loading().close()
                        })
                    } else {
                        activationLink(this.params).then(res => {
                            this.$loading().close()
                            // console.log('查看激活返回了什么',res)
                            if (res.code == 20005) {
                                this.$message.error('Account has already been activated')
                            } else {
                                this.$message.success('Submission successful, please check the email！')
                            }
                            this.onClose()
                        }, err => {
                            this.$loading().close()
                        })
                    }
                    
                } else {
                    return false
                }
            })
        },
        onClose() {
            this.params.email = ''
            this.params.captcha = ''
            this.params.key = ''
            this.isShow = false
        },
        checkCapslock(e) {
            const { key } = e;
            this.capsTooltip = key && key.length === 1 && key >= "A" && key <= "Z";
        },
        showPwd() {
            if (this.passwordType === "password") {
                this.passwordType = "";
            } else {
                this.passwordType = "password";
            }
            this.$nextTick(() => {
                this.$refs.password.focus();
            });
        },
        onClose() {
            this.isShow = false
            this.title = ''
        }
    }
}
</script>
<style lang="scss" scoped>
    .svg-icon {
        width: 1em;
        height: 1em;
        vertical-align: -0.15em;
        fill: currentColor;
        overflow: hidden;
    }

    .title {
        font-size: 30px;
        font-weight: bold;
        text-align: center;
        // text-shadow: 0px 1px 3px rgba(0,0,0,0.75);
    }

    .label-desc {
        color: #908C8C;
        font-size: 14px;
        font-weight: normal;
    }

    .required {
        color: #F56C6C;
        margin-left: 3px;
        margin-top: 6px;
    }

    .subbtn {
        background-color: #1095C1;
        color: white;
        font-size: 20px;
        font-weight: bold;
        border-radius: 10px;
        border: 0px solid black;
        padding: 15px 0;
        text-shadow: 1px 1px 3px rgba(0,0,0,0.2);
    }
    .subbtn:hover {
        background-color: #1095C1;
        color: white;
        font-size: 20px;
        font-weight: bold;
        border: none;
        padding: 15px 0;
        box-shadow: 0px 0px 8px 1px rgba(0,0,0,0.6);
        text-shadow: 1px 1px 3px rgba(0,0,0,0.6);
    }
    .subbtn:focus {
        background-color: #1095C1;
        color: white;
        font-size: 20px;
        font-weight: bold;
        border: none;
        padding: 15px 0;
        box-shadow: 0px 0px 8px 1px rgba(0,0,0,0.6);
        text-shadow: 1px 1px 3px rgba(0,0,0,0.6);
    }

    .bottom-desc {
        margin-top: 10px;
        text-align: center
    }

    .register {
        color: #3BA8CC;
    }

    .register:hover {
        color: #37b2db;
        // text-shadow: 1px 1px 1px rgba(0,0,0,0.75);
    }

    .tip {
        font-size: 14px;
        color: lightgrey;
    }

    .capt-image {
        width: 100px;
        height: 42px;
    }

    .refresh-btn {
        width: 100px;
        height: 100%;
    }
    .ctus {
        color: #52B2D2
    }
    .ctus:hover {
        color: #52B2D2;
        // text-shadow: 1px 1px 1px rgba(0,0,0,0.75);
    }

    ::v-deep {
        .el-form--inline .el-form-item {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
            /* text-align: right; */
        }
        .el-form--label-top .el-form-item__label {
            width: 100%;
            float: none;
            display: inline-block;
            text-align: left;
            padding: 0;
            line-height: 1;
            font-size: 16px;
            color: black;
        }

        .el-form--inline .el-form-item__content {
            width: calc(100% - 170px - 12px) !important;
            text-align: right;
            line-height: 35px;
        }
        .el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label-wrap>.el-form-item__label:before, .el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before {
            display: none;
        }

        .el-dialog { 
            border-radius: 10px;
            box-shadow: 0px 0px 8px 3px rgba(0,0,0,.6);
        }

        .el-input__inner {
            border-radius: 10px;
            padding: 20px;
            font-size: 16px;
        }
    }
</style>
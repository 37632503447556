<template>
    <div class="footer-box">
        <div class="corp">
            <div class="d-flex space-between" style="max-width: 1200px;margin: auto">
                <span>
                    Copyright @2023-2040 上海陶术生物科技有限公司 | 
                    <router-link to="/termofuse" class="footer-link">License Agreement For Demo Users</router-link> | 
                    <router-link to="/privacypolicy" class="footer-link">Privacy Policy</router-link>
                    <!--router-link to="/licenseagreement" class="footer-link">许可协议</router-link-->
                </span>
                
                <span>
                    网站备案号：
                    <a href="https://beian.miit.gov.cn/" target="_blank" class="footer-link">沪ICP备20019793号-9</a> 
                </span>
                <span> Technical Support (技术支持)：support@pdbbind-plus.org.cn</span>
            </div>
        </div>
    </div> 
</template>
<script>
import termofuse from '@/views/agreement/termofuse.vue'
import Cookies from 'js-cookie'
import Router from 'vue-router'

// 创建路由实例
const router = new Router({
  mode: 'history', // 使用 history 模式避免哈希
  routes: [
    {
      path: '/termofuse',
      name: 'term-of-use',
      component: termofuse,
    },
    // ...定义其他路由
  ]
})

export default {
  name: 'Foot',
  mounted() {
    //// 第一个百度统计脚本
    //var _hmt = _hmt || [];
    //(function() {
    //  var hm = document.createElement("script");
    //  hm.src = "https://hm.baidu.com/hm.js?928537d042a391d9393e917adb53dcb2";
    //  var s = document.getElementsByTagName("script")[0]; 
    //  s.parentNode.insertBefore(hm, s);
    //})();

    // 第二个百度统计脚本
    var _hmt2 = _hmt2 || [];
    (function() {
      var hm2 = document.createElement("script");
      hm2.src = "https://hm.baidu.com/hm.js?948253995adcdfa77daf205d0ca6a35c";
      var s2 = document.getElementsByTagName("script")[0]; 
      s2.parentNode.insertBefore(hm2, s2);
    })();
  }
}
</script>
<style lang="scss" scoped>
.footer-box {
    width: 100%;
    background: #1F324F;
    color: white;
    font-size: 12px;
    margin-top: auto;
}

.content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2vw 0 1vw 0;
}

.logo {
    height: 50px;
}

p {
    margin-bottom: 15px;
}

.line {
    max-width: 1200px;
    height: 1px;
    background: white;
    margin: 0 auto;
}

.corp {
    width: 100%;
    background: #000e23;
    padding: 10px 0;
    font-size: 10px;
}

a {
    color: white;
}

.title {
    font-size: 14px;
    font-weight: bold;
    color: white;
}

.footer-item {
    text-align: left;
    padding-top: 10px;
}
.footer-link {
    color: white;
    text-decoration: none; /* 移除下划线 */
    transition: color 0.3s; /* 平滑颜色变换效果 */
}

.footer-link:hover {
    color: #aaa; /* 悬停时的颜色变化 */
    text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.7); /* 添加阴影效果 */
}
</style>
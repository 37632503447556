import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Cookies from 'js-cookie'; // 确保你已经安装了js-cookie库

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/home/Index.vue')
  },
  {
    path: '/browse/:code?',
    name: 'browse',
    component: () => import('../views/browse/Index.vue')
  },

  {
    path: '/data',
    name: 'data',
    children: [
      {path: 'affinity', name: 'Affinity', component: () => import('@/views/data/Affinity.vue')},
      {path: 'ligand', name: 'Ligand', component: () => import('@/views/data/Ligand.vue')},
      {path: 'ligandresult', name: 'Ligand Search Result', component: () => import('@/views/data/LigandResult.vue')},
      {path: 'sequence', name: 'Sequence', component: () => import('@/views/data/Sequence.vue')},
      {path: 'sequenceresult', name: 'Sequence Search Result', component: () => import('@/views/data/SequenceResult.vue')}
    ],
    component: () => import('@/views/data/Index.vue')
  },
  {
    path: '/data/search',
    name: 'search',
    component: () => import('@/views/data/Search.vue')
  },
  {
    path: '/download',
    name: 'download',
    component: () => import('@/views/download/Index.vue')
  },
  {
    path: '/casf',
    name: 'casf',
    component: () => import('@/views/casf/Index.vue')
  },
  {
    path: '/comet',
    name: 'comet',
    //children: [
    //  {path: 'search', name: 'Search', component: () => import('@/views/coemt/Search.vue')},
    //  {path: 'result', name: 'Result', component: () => import('@/views/comet/Result.vue')}
    //],
    component: () => import('@/views/comet/Index.vue')
  },
  {
    path: '/xlogp3+',
    name: 'xlogp3+',
    component: () => import('@/views/xlogp3+/Index.vue')
  },
  {
    path: '/xscore',
    name: 'xscore',
    component: () => import('@/views/xscore/Index.vue')
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import('@/views/support/faq.vue')
  },
  {
    path: '/contact-us',
    name: 'contact-us',
    component: () => import('@/views/support/contact-us.vue')
  },
  {
    path: '/termofuse',
    name: 'term-of-use',
    component: () => import('@/views/agreement/termofuse.vue')
  },
  {
    path: '/privacypolicy',
    name: 'privacy-policy',
    component: () => import('@/views/agreement/privacypolicy.vue')
  },
  {
    path: '/licenseagreement',
    name: 'license-agreement',
    component: () => import('@/views/agreement/licenseagreement.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/register/Index.vue')
  },
  {
    path: '/resetpwd',
    name: 'resetpwd',
    component: () => import('@/views/register/resetpwd.vue')
  },
  {
    path: '/account-activation',
    name: 'account-activation',
    component: () => import('@/views/register/accountActivation.vue')
  },
  {
    path: '/subscribe',
    name: 'subscribe',
    component: () => import('@/views/subscribe/fillInInfo.vue')
  },
  {
    path: '/paypal-success',
    name: 'paypal-success',
    component: () => import('@/views/subscribe/components/paypal-success.vue')
  },
  {
    path: '/account',
    name: 'account',
    component: () => import('@/views/account/index.vue')
  },
  {
    path: '/privacy_policy', 
    name: 'privacyPolicy',
    component: () => import('@/views/register/privacyPolicy.vue')
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import('@/views/register/terms.vue')
  },
  {
    path: '/reference',
    name: 'reference',
    component: () => import('@/views/reference/Index.vue')
  },
  {
    path: '/admin',
    name: 'admin',
    children: [
      {path: 'index', name: 'index', component: () => import('@/views/admin/index/index.vue')},
      {path: 'check', name: 'check', component: () => import('@/views/admin/Check.vue')},
      {path: 'user', name: 'user', component: () => import('@/views/admin/User.vue')},
      {path: 'deposit', name: 'deposit', component: () => import('@/views/admin/Deposit.vue')},
      {path: 'feedback', name: 'feedback', component: () => import('@/views/admin/Feedback.vue')},
      {path: 'visitor', name: 'visitor', component: () => import('@/views/admin/Visitor.vue')},
      {path: 'news', name: 'news', component: () => import('@/views/admin/News.vue')}
    ],
    component: () => import('@/views/admin')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  const userInfo = Cookies.get('userInfo') ? JSON.parse(Cookies.get('userInfo')) : null;
  
  // 检查即将进入的路由是否为管理界面
  if (to.matched.some(record => record.path.startsWith('/admin'))) {
    // 检查用户角色是否符合要求
    if (userInfo && userInfo.role_id === 1) {
      // 如果是管理员，允许进入
      next();
    } else {
      // 如果不是管理员，重定向到首页或登录页
      next({ path: '/' });
      // 或者可以提示用户没有权限
      // Vue.prototype.$message.error('您没有权限访问该页面');
      // next(false);
    }
  } else {
    // 对于非管理界面，所有用户都可以进入
    next();
  }
});

export default router

<template>
    <el-dialog style="z-index: 111111"
        title="Welcome back"
        :visible.sync="isShow"
        width="400px"
        modal-append-to-body
        append-to-body
        center
        :close-on-click-modal="false"
        @close="onClose">
        <div slot="title" class="title">
            <div class="d-flex align-center">
                <i class="icon el-icon-user-solid"></i>
                <div class="subtitle ml-1">User {{ name }}</div>
            </div>
            
        </div>
        <hr>
            <div>
                <i class="check el-icon-check"></i><span class="desc">Download <span style="color: #0070C0">{{ latestVersion }} dateset</span></span>
            </div>
            <div class="mt-1">
                <i class="check el-icon-check"></i><span class="desc">Search within <span style="color: #0070C0">{{ latestVersion }} data</span></span>
            </div>
            <div class="mt-1">
                <i class="check el-icon-check"></i><span class="desc"><span style="color: #0070C0">Unlimited</span> target prediction use</span>
            </div>
            <div class="mt-1">
                <i class="check el-icon-check"></i><span class="desc">Browse all PDBbind data</span>
            </div>
            <div class="button mt-2" @click="onSubscribe">
                Subscribe to {{ latestVersion }}
            </div>
    </el-dialog>
</template>
<script>
import Cookies from 'js-cookie'

/** api */
import { getVersionList } from '@/assets/api/order.js'

export default {
    data: () => ({
        isShow: false,
        name: '',
        userInfo: Cookies.get('userInfo') ? JSON.parse(Cookies.get('userInfo')) : null,
        latestVersion: ''
    }),
    methods: {
        onShow(name) {
            this.name = name
            
            this.$loading()
            getVersionList().then(res => {
                this.$loading().close()
                this.isShow = true
                if (res.code == 0) {
                    this.latestVersion = res.data.current_version.version_name
                } else {
                    this.$message.error(res.data.msg)
                }
            })
        },
        onClose() {
            this.isShow = false
        },
        onSubscribe() {
            this.$router.push('/fillin')
        }
    }
}
</script>
<style lang="scss" scoped>
    .icon {
        font-size: 40px;
        color: #0070C0;
    }

    .subtitle {
        display: inline-block;
    }

    .line {
         border-bottom: 1px solid black;
    }

    .check {
        color: #1079C4;
        font-size: 25px;
        margin-right: 10px;
        font-weight: bold;
    }

    .button {
        width: 100%;
        height: 50px;
        background: #0070C0;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    .desc {
        font-size: 16px;
        font-weight: bold;
        color: black;
    }

    ::v-deep {
        .el-dialog--center .el-dialog__body {
            text-align: initial;
            padding: 0px 25px 30px;
        }
    }
</style>
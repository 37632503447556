import request from './index'
import { toFormData } from '@/assets/utils';

// 登录
export function login(data) {
    return request({
        url: '/login',
        method: 'POST',
        data
    })
}

// 验证码
export function getCaptcha(data) {
    return request({
        url: '/captcha',
        method: 'POST',
        data
    })
}

// 退出登录
export function logout(data) {
    return request({
        url: '/logout',
        method: 'GET',
        data
    })
}
// 获取更全的用户信息
export function getAllinfo() {
    return request({
        url: 'user/info',
        method: 'GET'
    })
}

//获取用户注册信息
export function getReginfo() {
    return request({
        url: '/user/reginfo',
        method: 'GET'
    })
}

// 更新用户信息
export function updateReginfo(data) {
    return request({
        url: '/user/update',
        method: 'POST',
        data
    })
}

// 购买记录
export function getPurchaseList() {
    return request({
        url: '/purchase/list',
        method: 'GET'
    })
}

// 忘记密码
export function forgetPassword(data) {
    return request({
        url: '/forget-password',
        method: 'POST',
        data
    })
}

// 重置密码
export function resetPassword(data) {
    return request({
        url: '/reset-password',
        method: 'POST',
        data
    })
}

// 邮箱激活
export function activationLink(data) {
    return request({
        url: '/activation-link-apply',
        method: 'POST',
        data
    })
}

// 邮箱验证
export function activationLinkCheck(data) {
    return request({
        url: '/activation-link-check',
        method: 'POST',
        data
    })
}

// 获取国家列表
export function getCountryList(params) {
    return request({
        url: '/country',
        method: 'GET',
        params
    })
}
import axios from 'axios'
import { Message, MessageBox } from 'element-ui'
import Cookies from 'js-cookie'
import { goRefresh } from './oauth'
import router from '../../router'
import that from '../../main'

const CancelToken = axios.CancelToken

const baseURL = setBaseDomain() + process.env.VUE_APP_BASE_API

// const baseURL = process.env.VUE_APP_BASE_DOMAIN + process.env.VUE_APP_BASE_API

// 注释该条后，F12控制台不再显示baseUrl信息，console.log('baseUrl:', process.env.VUE_APP_PROXY_API)

const service = axios.create({
  baseURL,
  timeout: 60000
})

service.interceptors.request.use(
  
    config => {
      if (Cookies.get("authorization") && Cookies.get("authorization") != 'undefined' && config.url != '/calculate/molExport') {
        config.headers['authorization'] = `${Cookies.get("authorization")}`
      } else {
        // config.headers['authorization'] = `Bearer amlmZW46djE4YWc1NmZkMWduNGp5ag==`
      }
      config.cancelToken = new axios.CancelToken(cancel => {
        // store.dispatch("addCancelToken", cancel)
          // store.addCancelToken(cancel)
      })
      // store.state.isLoading = true
      return config
    },
    error => {
      return Promise.reject(error)
    }
  )

  service.interceptors.response.use(
    response => {
      const res = response.data
      if (response.code && response.code == 503) {
        setMessage("请稍后再试!")
        return Promise.reject(new Error(response.error || '请稍后再试!'))
      }
      return new Promise((resolve, reject) => {
        // resolve(res)
        if (res.code == 10004 ) {
          //token过期
          goRefresh()
          // setMessage(res.msg)
          reject(res.msg || '未知错误,请联系管理员!')
          // return Promise.reject(new Error(res.msg || '未知错误,请联系管理员!'))
        } else if (res.code == 10012) {
          setMessage ('请重新登录！')
          router.push({name: 'home', params: { login: true }})
        } else if (res.code == 30006 || res.code == 30004 || res.code == 10011) {
          // debugger
          // setMessage(res.msg)
          console.log('baseUrl:', router.currentRoute)
          // debugger
          console.log('route:' + router.currentRoute.params.login)
          if (router.currentRoute.query.login == undefined) {
            router.replace({
              name: router.currentRoute.name,
              query: {
                login: true
              }
            })
            window.location.reload()
          } else {
            router.replace({
              name: router.currentRoute.name,
              query: {
              }
            })
          }
          
          // debugger
          // if (router.currentRoute.query.login == undefined) {
          //   router.currentRoute.query.login = true
            // console.log('baseUrl:', router.currentRoute)
            // router.push({name: 'home', params: { login: true }})
            // console.log('route:' + router.currentRoute.params.login)
            // window.location.reload()
          // }
          
          reject(res.msg || '未知错误,请联系管理员!')
        }  else {
            resolve(res)
        }
      })
    },
    error => {
      if (error instanceof axios.Cancel) return
      return Promise.reject(error)
    }
  )

  // 异常提示
function setMessage (message, type = "error") {
  // console.error(message)
  Message({
    message,
    type,
    dangerouslyUseHTMLString: true,
    duration: 5 * 1000
  })
}
// 提示

// token 失效
export function goLoginPage () {
  // Cookies.get("REFRESH_TOKEN")
  // CookieRemove("EXPIRES")
  // CookieRemove("TOKEN")
  // CookieRemove("USER_ID")
  // CookieRemove("USER_NAME")
  // setMessage("登录凭证失效了,请重新登录！")
  // router.push("/login")
}

// 设置 请求路径 Domain
export function setBaseDomain () {
  const currentURL = window.location.origin; // 10.10.26.43:81
  if (currentURL) {
    // 访问域名 - 请求路径 走 外网域名
    return process.env.VUE_APP_BASE_DOMAIN
  } else {
    // 访问ip - 请求路径 走 内网ip
    return process.env.VUE_APP_BASE_DOMAIN_IP
  }
}

  export default service
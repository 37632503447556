<template>
    <div style="flex: 1;" class="bg">
        <Header ref="header" activeIndex="/termofuse" />
        <div class="decorative-rectangle">
             <!-- Title -->
            <div :class="{'title-cn': lang == 'cn', 'title-en': lang == 'en'}">
                {{ lang == 'en' ? 'LICENSE AGREEMENT FOR DEMO USERS' : '试用用户版权许可协议'}} 
                <span :class="{'lang-cn': lang == 'cn', 'lang-en': lang == 'en'}">
                    <span :class="lang == 'cn' ? 'lang-btn active' : 'lang-btn'" @click="lang = 'cn'">中文</span> | <span :class="lang == 'en' ? 'lang-btn active' : 'lang-btn'" @click="lang = 'en'">English</span>
                </span>
            </div>
            <!-- Content -->
            <div v-if="lang == 'cn'">
                <div class="mt-1 line">
                    PDBbind+是一个整合生物复合物三维结构和亲合性实验数据的数据库并使用功能模块提供在线计算服务的综合性网络平台。
                    PDBbind+网络数据库的相关知识产权由复旦大学(以下简称“复旦大学”)所有，该网络数据库由上海陶术生物技术有限公司 (以下简称“上海陶术”) 负责运营。
                </div>
                <div class="mt-1 line">
                    <span class="underline">本协议包括复旦大学/上海陶术与试用用户（以下简称"用户"或"您"）之间关于使用PDBbind+网络数据库的各项条款，具有正式书面合同的效力。
                    用户在PDBbind+平台上完成注册并在线批准本协议之后，即享有PDBbind+平台规定可免费使用的数据和计算服务。</span>
                </div>
                <div class="mt-1 line-title">
                    第1条 服务条款的确认及接受
                </div>
                <div class="mt-1 line">
                    1.1 在使用PDBbind+平台提供的数据和计算服务之前，用户应仔细阅读本协议中的全部规则及其他相关服务条款、服务规则或规范的内容，确认对本协议及各项服务规则已知晓、
                    理解并接受，并已同意将其作为确定双方权利义务的依据。所有服务规则视为本协议不可分割的一部分，与本协议具有同等法律效力。
                </div>
                <div class="mt-1 line">
                    1.2 当您按照注册页面上的提示填写信息、同意本协议并完成全部注册程序时，即表示您已充分阅读、理解并接受本协议的全部内容，本协议在您与我们之间即成立并生效。
                    阅读本协议的过程中，如果您不同意本协议或其中任何条款约定，您应停止注册、提交或确认订单的流程。
                </div>
                <div class="mt-1 line">
                    1.3 您理解并同意，因业务的调整等原因，我们可能会对本协议或各项服务规则进行修改更新，因此有关您的权利义务内容也会随之变化。我们修改本协议或各项服务规则时， 
                    会以合理的方式通知您，包括但不限于在网站显著位置公布、弹窗提示、消息推送等方式。如您有异议，可以在本协议修改或服务规则生效前的7日内向我们提出异议。
                    如果您在阅读完服务协议内容后未在前述期限内提出异议，继续使用或接受PDBbind+平台的服务，即视为您已充分知晓、理解并同意接受更新后的服务协议。
                </div>


                <div class="line-title mt-2">
                    第2条 知识产权及提供的服务
                </div>
                <div class="line mt-1">
                    2.1 PDBbind+平台上的数据信息（包括但不限于数据、图表、标识、按钮图标、图像）受中国和国际版权法的保护。
                </div>
                <div class="line mt-1">
                    2.2 <span class="underline">我们授权给您个人的、不可转让的、非独占的使用PDBbind+平台上规定可免费使用的数据和计算服务。这些使用权利旨在帮助您熟悉PDBbind+网络数据库。
                        授权用户这些使用权利并不向用户转移版权或其他知识产权。本协议未明示授权的其他一切权利仍由复旦大学与上海陶术保留。</span>
                </div>
                <div class="line mt-1">
                    2.3 <span class="underline">除非得到复旦大学与上海陶术的特别书面授权，您不得以任何方式对PDBbind+平台提供的服务内容进行包括但不限于改编、垂直搜索、镜像等访问使用，
                        您不得以任何方式非法抓取、复制或以其他方式引用PDBbind+平台提供的数据信息。</span>
                </div>
                <div class="line mt-1">
                    2.4 <span class="underline">特别需要强调的是：您不得以任何方式向第三方传播、分发、出借、或转让从PDBbind+获得的原始数据。您可以基于从PDBbind+获得的原始数据生成衍生数据，但是这些衍生数据仅限于您本人与您的同事内部使用。
                    未经复旦大学与上海陶术的特别书面授权，您同样不得以任何方式向第三方传播、分发、出借、或转让您生成的衍生数据。</span>
                </div>
                <div class="line mt-1">
                    2.5 如果我们有合理证据证明您存在以上侵犯知识产权行为，复旦大学与上海陶术有权要求您承担相应的侵权责任。同时，您应全额赔偿复旦大学与上海陶术因您的侵权行为所遭受的损失，
                    包括但不限于仲裁费、诉讼费、律师费、差旅费及复旦大学与上海陶术因此支出的全部合理费用。
                </div>
                <div class="line mt-1">
                    2.6 您可以通过访问PDBbind+平台获取我们提供的数据和计算服务。若您通过未经授权的第三方获取这些服务，我们无法保证服务的正常使用，由此造成的损失与复旦大学或上海陶术均无关。
                </div>


                <div class="line-title mt-2">
                    第3条 账户注册与行为规范
                </div>
                <div class="line mt-1">
                    3.1 您在注册和使用PDBbind+平台账户时，应保证提供真实、准确和完整的信息。未经他人许可，您不得用他人名义（包括但不限于冒用他人姓名、邮箱）开设账号。如果我们发现您提供的注册信息不真实、
                        不准确或不完整，或者有恶意批量注册账户的行为，我们有权采取必要的措施，包括但不限于冻结或关闭账户、取消订单、拒绝提供服务等，并保留追究相关法律责任的权利。
                </div>
                <div class="line mt-1">
                    3.2 <span class="underline">账户是我们识别您并向您提供服务的依据。您应负责维护您的账户的保密性并确保您的登录密码未向任何第三方透露。
                        您不得在未经我们许可的前提下有偿或无偿转让、或向第三方出借账户。我们理解并且允许您在必要时与在同一工作单位的同事共享您的账户，
                        但是前提是您的同事已阅读并同意本协议的所有内容，并且您本人愿意承担与他人共享账户而可能造成的违反本协议相关条款带来的后果。</span>
                </div>
                <div class="line mt-1">
                    3.3 如果我们根据本协议中约定的违约认定程序及标准判断您的账户的使用可能危及您的账户安全或PDBbind+平台信息安全的，我们有权按照相关法律法规及服务规则限制、 
                    冻结或终止您对账户的使用或终止本协议，由此给您带来的损失（包括但不限于通信中断，用户资料及相关数据等的清空等）由您自行承担。
                </div>
                <div class="line mt-1">
                    3.4 您应当对在PDBbind+平台上的行为负责。除非法律允许或经我们事先书面许可，您不得在使用PDBbind+平台服务时发生以下行为：<br />
                    （1） 使用未经我们授权或许可的任何插件、外挂、系统或第三方工具对PDBbind+平台的正常运行进行干扰、破坏、修改或施加其他影响。<br />
                    （2） 利用PDBbind+平台进行任何危害计算机网络安全的行为，包括但不限于非法侵入他人网络、干扰他人网络正常功能、窃取网络数据等。<br />
                    （3） 企图对PDBbind+平台进行反向工程、反向汇编、编译或以其他方式尝试发现本网站的源代码。<br />
                    （4） 违反任何地方、省/州、国家和国际法律或规定，包括但不限于知识产权法。
                </div>
                <div class="line mt-1">
                    3.5 如果我们发现或有理由认为您的行为违反或可能违反上述规定，复旦大学与上海陶术可以不经通知独立判断并采取任何我们认为适当的行动，
                    包括但不限于暂停或终止向您提供服务，并追究您的所有法律责任。
                </div>


                <div class="line-title mt-2">
                    第4条 用户信息的保护
                </div>
                <div class="line mt-1">
                    4.1 我们致力于保护用户个人信息的安全。在使用PDBbind+平台服务的过程中，可能需要用户提供个人信息（如姓名、联系方式等）。除非得到用户的明确同意或法律法规的明确规定，
                    我们不会向任何非关联第三方披露用户的个人信息。用户有权随时访问和更正其个人信息，也有权要求我们删除其个人信息，除非法律规定必须保留这些信息。
                    若出现任何关于用户个人信息的泄露事件，我们将按照法律的要求及时通知用户。
                </div>
                <div class="line mt-1">
                    4.2 用户通过PDBbind+平台上传的数据文件，将受到严格的保护。未经用户明确授权，我们不会向任何第三方披露、传递或使用该等数据文件。同时，
                    我们会采取适当的安全措施以保护用户数据的安全和完整性。
                </div>


                <div class="line-title mt-2">
                    第5条 免责声明
                </div>
                <div class="line mt-1">
                    5.1 我们不保证PDBbind+平台提供的数据和计算服务不会中断或无错误，不保证会纠正所有缺陷，也不保证提供的服务能满足用户的所有要求。
                </div>
                <div class="line mt-1">
                    5.2 我们不对因通信系统或互联网的中断、技术故障、计算机错误或病毒、信息损坏或丢失、或其他非复旦大学与上海陶术控制范围内的原因而产生的任何性质的损害向用户或任何第三方承担赔偿责任。
                </div>
                <div class="line mt-1">
                    5.3 我们需要定期或不定期对相关网络系统、设备进行检修、维护或升级,如因此造成服务在合理时间内的中断, 复旦大学与上海陶术无须为此承担任何责任，但将尽可能事先进行通知。
                </div>


                <div class="line-title mt-2">
                    第6条 通知
                </div>
                <div class="line mt-1">
                    6.1 所有针对用户的通知将通过PDBbind+平台线上服务平台的公告、电子邮件或常规信件进行发送。此类通知可能会对用户的权利和义务产生重大影响，故用户应确保及时关注。
                </div>
                <div class="line mt-1">
                    6.2 服务条款的修改、服务变更或其他重要事件的通知，将通过上述渠道进行。除非另有明确规定，通知应通过电子邮件形式发送至用户在注册过程中提供的电子邮件地址， 
                    或由相关方指定的其他地址。
                </div>


                <div class="line-title mt-2">
                    第7条 法律适用与争议解决
                </div>
                <div class="line mt-1">
                    7.1 本协议的订立、执行和解释以及任何与本协议有关的争议都应适用中华人民共和国大陆地区的有效法律（不包括冲突法规则）。
                    若本协议有特定条款与适用法律发生冲突，该条款将按照法律重新解释，而其它条款继续有效。
                </div>
                <div class="line mt-1">
                    7.2 若双方在本协议内容或执行过程中产生争议，应首先通过友好协商解决。协商不成时，双方一致同意将争议提交至上海仲裁委员会，
                    依据该委员会当时有效的仲裁规则进行仲裁。仲裁裁决是最终的且对双方均有约束力。仲裁费用由败诉方承担。
                </div>
                <div class="foot">
                    协议最后修订日期：2024年3月23日
                </div>
            </div>


            <div v-if="lang == 'en'">
                <div class="mt-1 line">
                    PDBbind+ is a comprehensive online platform integrating a database of three-dimensional structures of biological complexes 
                    and experimental binding data and offering online computational services through functional modules. The intellectual property 
                    of PDBbind+ is owned by Fudan University in Shanghai, China (hereinafter referred to as "Fudan University"). PDBbind+ is operated 
                    jointly by TopScience Ltd in Shanghai, China (hereinafter referred to as "TopScience"). 
                </div>
                <div class="mt-1 line">
                    <span class="underline">This agreement includes the terms between Fudan University/TopScience and demo users (hereinafter 
                        referred to as "User" or “You”) regarding the use of PDBbind+, and has the legal effect of a formal written contract. 
                        After User completes registration on the PDBbind+ platform and approves this agreement, User is entitled to the data and 
                        computational services provided free of charge on the PDBbind+ platform. </span>
                </div>
                <div class="mt-1 line-title">
                    Article 1. Acceptance and Acknowledgment of Service Terms
                </div>
                <div class="mt-1 line">
                    1.1 <span class="underline">Before using the data and computational services provided by the PDBbind+ platform, 
                        you should carefully read all the rules in this agreement as well as other related service terms, service rules, 
                        or specifications, confirming awareness, understanding, and acceptance of this agreement and agreeing to use it 
                        as the basis for determining both parties' rights and obligations. All service rules are considered an inseparable 
                        part of this agreement, with the same legal effect.</span>
                </div>
                <div class="mt-1 line">
                    1.2 When you follow the instructions on the registration page to fill in information, agree to this agreement, 
                    and complete all registration procedures, it indicates that you have fully read, understood, and accepted the entire content 
                    of this agreement, which then becomes effective between you and us. During the process of reading this agreement, if you do 
                    not agree with this agreement or any of its terms, you should cease the registration, submission, or confirmation of the order process.
                </div>
                <div class="mt-1 line">
                    1.3 You understand and agree that due to adjustments in business or other reasons, we may modify or update this agreement or 
                    various service rules, thereby changing the content of your rights and obligations. When we modify this agreement or service 
                    rules, we will notify you in a reasonable manner, including but not limited to publishing on a prominent position on the website, 
                    pop-up prompts, message pushes, etc. If you object, you may raise objections to us within 7 days before the modification of this 
                    agreement or service rules takes effect. If you continue to use or accept the services of the PDBbind+ platform after reading the 
                    updated service agreement and not raising objections within the aforementioned period, it is deemed that you are fully aware, understand, 
                    and agree to accept the updated service agreement.
                </div>


                <div class="line-title mt-2">
                    Article 2. Intellectual Property and Provided Services
                </div>
                <div class="line mt-1">
                    2.1 The data information on the PDBbind+ platform (including but not limited to data, charts, logos, button icons, images) is protected 
                    by Chinese and international copyright laws.
                </div>
                <div class="line mt-1">
                    2.2 <span class="underline">We grant you a personal, non-transferable, non-exclusive right to use the data and computational services provided free of 
                        charge on the PDBbind+ platform. This right is intended to assist you in becoming familiar with PDBbind+. Granting you this right does not imply 
                        the transfer of copyright or any other intellectual properties to you. All other rights not expressly authorized in this agreement are also reserved 
                        by Fudan University and TopScience.</span>
                </div>
                <div class="line mt-1">
                    2.3 <span class="underline">Unless specially authorized in writing by Fudan University and TopScience, you may not use the content provided by 
                        the PDBbind+ platform in any way, including but not limited to adaptation, vertical search, mirroring, and etc. You may not illegally scrape, 
                        copy, or otherwise reference the data information provided by the PDBbind+ platform. </span>
                </div>
                <div class="line mt-1">
                    2.4 <span class="underline">It should be emphasized that: you may not, in any way, re-distribute, disseminate, lend, or transfer the raw data obtained from 
                    the PDBbind+ platform to third parties. You may generate derivative data based on the raw data obtained from PDBbind+, but these derivative data are 
                    limited to internal use only by yourself and members of your organization at the same site. Without specific written authorization from Fudan University 
                    and TopScience, you also may not, in any way, re-distribute, disseminate, lend, or transfer the derivative data you generate to third parties.</span>
                </div>
                <div class="line mt-1">
                    2.5 If we have reasonable evidence to prove that you have infringed upon intellectual property rights, Fudan University and TopScience reserve the right 
                    to hold you accountable for the corresponding infringement. Additionally, you shall fully compensate Fudan University and TopScience for any losses incurred 
                    due to your infringement, including but not limited to arbitration fees, litigation fees, attorney fees, travel expenses, and all reasonable expenses incurred 
                    by Fudan University and TopScience due to such infringement.
                </div>
                <div class="line mt-1">
                    2.6 You may access the data and computational services provided by us through the PDBbind+ platform. If you obtain these services through unauthorized 
                    third parties, we cannot guarantee the normal use of the services, and any losses incurred as a result will not be the responsibility of Fudan University 
                    or TopScience.
                </div>


                <div class="line-title mt-2">
                    Article 3. Account Registration and Conduct Standards
                </div>
                <div class="line mt-1">
                    3.1 When registering and using the PDBbind+ platform account, you must ensure that the information provided is true, accurate, and complete. Without 
                    the permission of others, you may not open an account in someone else's name (including but not limited to impersonating another's name, email). 
                    If we find that the registration information you provide is not true, inaccurate, or incomplete, or if there is malicious bulk registration of accounts,
                     we reserve the right to take necessary measures, including but not limited to freezing or closing the account, canceling orders, refusing to provide 
                     services, etc., and retain the right to pursue relevant legal responsibilities.
                </div>
                <div class="line mt-1">
                    3.2 <span class="underline">Your account serves as the basis for identifying you and providing services to you. You are responsible for maintaining 
                        the confidentiality of your account and ensuring that your login password is not disclosed to others. You may not transfer or lend your account 
                        to any third party, whether for compensation or free of charge, without our writing permission. However, we understand and allow you to share your 
                        account with members of your organization at the same site when necessary, provided that they have read and agreed to all the terms of this agreement, 
                        and you are willing to bear the consequences of sharing your account with others that may violate the terms of this agreement.</span>
                </div>

                <div class="line mt-1">
                    3.3 If we judge, according to the breach identification procedures and standards stipulated in this agreement, that the use of your account may 
                    jeopardize the security of your account or the information security of the PDBbind+ platform, we have the right to restrict, freeze, or terminate 
                    your use of the account or terminate this agreement in accordance with relevant laws, regulations, and service rules. Any losses caused to you 
                    (including but not limited to communication interruption, clearing of your data and related data, etc.) shall be borne by you.
                </div>
                <div class="line mt-1">
                    3.4 You are responsible for your conduct on the PDBbind+ platform. Unless permitted by law or authorized in writing by us in advance, 
                    the following actions are prohibited while using PDBbind+ platform services:<br />
                    (1) Using any unauthorized or unlicensed plugins, cheats, systems, or third-party tools to interfere, disrupt, modify, or otherwise affect the normal operation of the PDBbind+ platform.<br />
                    (2) Engaging in any behavior that harms computer network security on the PDBbind+ platform, including but not limited to illegal intrusion into others' networks, interference with the normal functions of others' networks, theft of network data, etc.<br />
                    (3) Attempting reverse engineering, decompiling, compiling, or otherwise trying to discover the source code of the PDBbind+ platform.<br />
                    (4) Violating any local, provincial/state, national, and international laws or regulations, including but not limited to intellectual property laws.
                </div>
                <div class="line mt-1">
                    3.5 If we discover or have reason to believe that your conduct violates or may violate the above provisions, Fudan University and TopScience can 
                    independently judge and take any action we deem appropriate without notice, including but not limited to suspending or terminating the provision 
                    of services to you, and holding you legally responsible for all actions.
                </div>


                <div class="line-title mt-2">
                    Article 4. Protection of User’s Information
                </div>
                <div class="line mt-1">
                    4.1 We are committed to protecting the security of your personal information. During the use of PDBbind+ platform services, you may be required 
                    to provide personal information (such as name, contact information, etc.). Unless with the explicit consent of you or as expressly provided by 
                    laws or regulations, we will not disclose your personal information to any non-affiliated third parties. You have the right to access and correct 
                    their personal information at any time, and also have the right to request us to delete their personal information, unless law or regulation requires 
                    us to retain such information. In the event of any personal information leakage incident, we will promptly notify you in accordance with legal requirements.
                </div>
                <div class="line mt-1">
                    4.2 Data files uploaded by you through the PDBbind+ platform will be strictly protected. Without explicit authorization from you, we will not disclose, 
                    transfer, or use such data files to any third parties. Additionally, we will take appropriate security measures to protect the security and integrity 
                    of your data.
                </div>


                <div class="line-title mt-2">
                    Article 5: Disclaimer
                </div>
                <div class="line mt-1">
                    5.1 We do not guarantee that the data and computational services provided by the PDBbind+ platform will be uninterrupted or error-free, nor do we 
                    guarantee that all defects will be corrected, or that the services will meet all your requirements.
                </div>
                <div class="line mt-1">
                    5.2 We shall not be liable for any damages of any kind to you or any third parties due to interruptions, technical failures, computer errors or 
                    viruses, information damage or loss, or other causes beyond the control of Fudan University and TopScience, including issues with communication 
                    systems or the internet.
                </div>
                <div class="line mt-1">
                    5.3 We may need to periodically or irregularly perform maintenance, repairs, or upgrades to relevant network systems or equipment. If such 
                    activities result in reasonable interruptions of the service, Fudan University and TopScience shall not bear any responsibility, but will 
                    endeavor to provide advance notice whenever possible.
                </div>


                <div class="line-title mt-2">
                    Article 6: Notifications
                </div>
                <div class="line mt-1">
                    6.1 All notifications to you will be sent via announcements on the online service platform of PDBbind+, email, or regular mail. Such notifications 
                    may have significant impact on Use’s rights and obligations, so you should ensure to pay timely attention.
                </div>
                <div class="line mt-1">
                    6.2 Notifications of modifications to the service terms, service changes, or other important events will be communicated through the aforementioned 
                    channels. Unless otherwise explicitly specified, notifications should be sent electronically to the email address provided by you during the 
                    registration process, or to another address designated by the relevant parties.
                </div>

                <div class="line-title mt-2">
                    Article 7. Applicable Law and Dispute Resolution
                </div>
                <div class="line mt-1">
                    7.1 The establishment, implementation, and interpretation of this agreement, as well as any disputes related to this agreement, shall be governed 
                    by the valid laws of Mainland China of the People's Republic of China (excluding conflict of laws rules). If any specific provision of this agreement 
                    conflicts with applicable law, that provision shall be reinterpreted in accordance with the law, while the other provisions shall remain valid.
                </div>
                <div class="line mt-1">
                    7.2 In the event of any dispute arising between the parties regarding the content or implementation of this agreement, the parties shall first 
                    attempt to resolve it amicably through negotiation. If negotiation fails, both parties agree to submit the dispute to the Shanghai Arbitration 
                    Commission for arbitration in accordance with the arbitration rules then in effect. The arbitration decision shall be final and binding on both parties. 
                    The costs of arbitration shall be borne by the party that loses the case.
                </div>
                <div class="foot">
                    Latest update: March 23, 2024
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Header from '@/components/header.vue'

export default {
    components: {
        Header
    },
    data() {
        return {
            lang: 'en',
        }
    },
    mounted() {
        this.$refs.header.$el.style.boxShadow = 'none';   // 移除header阴影
        // this.$refs.header.$refs.header.style.background = '#007EB7';
        // this.$refs.header.$refs.header.style.position = 'sticky'
        this.getDownloadList();

        if (this.$route.params.login || this.$route.query.login) {
            this.$refs.header.$refs.login.onShow()
        };
    },
    methods: {
        // 如果有需要的方法，可以在这里添加
    }
}
</script>

<style lang="scss" scoped>
    .bg {
        background: url('@/assets/images/background_blue_style1.png') no-repeat 100% top;
        background-size: 100% auto;
        min-width: 1700px;
    }

    .decorative-rectangle {
        background-color: #F5F8FB;
        border-radius: 10px;
        padding-top: 35px;
        padding-bottom: 45px;
        padding-left: 70px;
        padding-right: 70px;
        margin-top: 240px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        position: relative; // 为了绝对定位子元素
        margin-bottom: 60px;
        min-width: 1300px;
        max-width: 1420px;
        margin-left: auto;
        margin-right: auto;
    }

    .title-cn {
        min-width: 700px;
        text-align: center; // 文字居中
        position: absolute;
        top: -80px;
        left: 50%;
        transform: translateX(-50%); // 与left: 50%结合使用实现精确居中
        font-size: 55px;
        font-weight: bold;
        width:auto;
        color: #ffffff;
        white-space: nowrap; // Preventing the text from wrapping
    }
    .title-en {
        min-width: 700px;
        text-align: center; // 文字居中
        position: absolute;
        top: -65px;
        left: 50%;
        transform: translateX(-50%); // 与left: 50%结合使用实现精确居中
        font-size: 55px;
        font-weight: bold;
        width:auto;
        color: #ffffff;
        white-space: nowrap; // Preventing the text from wrapping
    }

    .content-box {
        padding: 50px;
        border-radius: 5px;
        margin-top: -30px;
        font-size: 16px;
        line-height: 20px;
        text-align: left;
        // 根据需要，添加更多样式
    }



.title-container {

    top: -100px; // 向上偏移，调整为标题的高度的一半
    left: 50%; // 水平居中
    transform: translateX(-50%); // 与left: 50%结合使用实现精确居中
    background: transparent; // 背景色
    border-radius: 10px; // 圆角
    padding: 5px 20px; // 内边距
}


.line-title {
    font-size: 20px;
    font-weight: bold;
    color: black;
    text-align: left;
}

.line {
    font-size: 16px;
    color: black;
    text-align: left;
    white-space: pre-line;
    text-align: justify;
}
.foot{
    margin-top:30px;
    font-size: 16px;
    color: black;
    text-align: right;
    white-space: pre-line;
    font-style: italic;
}
.underline {
    text-decoration: underline;

}
.lang-cn {
    position: absolute;
    font-size: 16px;
    right: -163px;
    line-height: 60px;
    top: 31px;
    font-weight: normal;

    .lang-btn {
        cursor: pointer;
    }

    .active {
        font-weight: bold;
        text-shadow: 0px -1px 1px black;;
    }
}
.lang-en {
    position: absolute;
    font-size: 16px;
    right: -140px;
    line-height: 60px;
    top: 16px;
    font-weight: normal;

    .lang-btn {
        cursor: pointer;
    }

    .active {
        font-weight: bold;
        text-shadow: 0px -1px 1px black;;
    }
}
    // 如果有其他的样式类，根据需要继续添加
</style>

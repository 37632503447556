import {toFormData} from '@/assets/utils';
import axios from 'axios';
// import {set as CookieSet} from '@js-cookie';
import Cookies from 'js-cookie'
import { Message } from 'element-ui'
import { goLoginPage, setBaseDomain } from './index';
// let baseURL = process.env.VUE_APP_BASE_DOMAIN
let baseURL = setBaseDomain()
export async function goLogin (data) {
    const { grant_type } = data // refresh_token
    return new Promise((resolve, reject) => {
        axios({
            url: "/api/login",
            method: "POST",
            data: toFormData(data),
            baseURL,
            headers: {
                'authorization': `Bearer `
            }
        }).then(res => {
            const results = res.data
            // debugger
            if (results.code == 0) {
                Cookies.set("userInfo", JSON.stringify(results.data))
                console.log(JSON.parse(Cookies.get("userInfo")))
                Cookies.set("authorization", res.headers.authorization)
                resolve(res)
            } else {
                // token无法使用，重新登录
                setMessage (results.msg)
                reject(err)
            }
            
        }).catch((err) => {
            reject(err)
        })
    })
}

export async function goRefresh (data) {
    // const { grant_type } = data // refresh_token
    return new Promise((resolve, reject) => {
        axios({
            url: "/api/login-check",
            method: "POST",
            data: toFormData(data),
            baseURL,
            headers: {
                'authorization': Cookies.get("authorization")
            }
        }).then(res => {
            const results = res.data
            if (results.code == 0) {
                setTokenForLocal(res.config)
                resolve(res)
                window.location.reload()
            } else {
                // token无法使用，重新登录
                setMessage (results.msg)
                Cookies.remove("userInfo")
                Cookies.remove("authorization")
                window.location.reload()
            }
            
        }).catch((err) => {
            reject(err)
        })
    })
}

// 注册

export async function goRegister (data) {
    return new Promise((resolve, reject) => {
        axios({
            url: "/api/register",
            method: "POST",
            data: toFormData(data),
            baseURL,
            headers: {
                'authorization': `Bearer `
            }
        }).then(res => {
            const results = res.data
            if (results.code == 0) {
                setTokenForLocal(res.config)
                resolve(results)
            } else {
                // token无法使用，重新登录
                reject(results)
            }
            
        }).catch((err) => {
            reject(err)
        })
    })
}

function setTokenForLocal (results) {
    // expires_in token 过期时间 => 单位:秒
    // |   2小时有效期  |       7天可重新获取的周期       |        无效了         |
    // 10004 Token has expired 的时候
    // code:0 成功 更新 authorization
    // code:10012  token无法使用了，这个时候就需要重新输入账户密码登录了
    Cookies.set("authorization", results.headers.authorization)
}


function setMessage (msg, type = "error") {
    Message({
      message: msg,
      type,
      dangerouslyUseHTMLString: true,
      duration: 5 * 1000
    })
}

/**自定义 Picker Options */
export function getCustomPickerOptions () {
    return {
      shortcuts: [{
        text: '今日',
        onClick(picker) {
          const end = getNowDate();
          const start = getNowDate();
          picker.$emit('pick', [start, end]);
        }
      },{
        text: '昨日',
        onClick(picker) {
          const end = getNowDate(-1);
          const start = getNowDate(-1);
          picker.$emit('pick', [start, end]);
        }
      },{
        text: '本周',
        onClick(picker) {
          const d = getSpecifiedData(new Date(), "week")
          const end = d.endDate;
          const start = d.startDate;
          picker.$emit('pick', [start, end]);
        }
      },{
        text: '上周',
        onClick(picker) {
          const d = getSpecifiedData(new Date(), "lastWeek")
          const end = d.endDate;
          const start = d.startDate;
          picker.$emit('pick', [start, end]);
        }
      },{
        text: '本月',
        onClick(picker) {
          const d = getSpecifiedData(new Date(), "month")
          const end = d.endDate;
          const start = d.startDate;
          picker.$emit('pick', [start, end]);
        }
      },{
        text: '上月',
        onClick(picker) {
          const d = getSpecifiedData(new Date(), "lastMonth")
          const end = d.endDate;
          const start = d.startDate;
          picker.$emit('pick', [start, end]);
        }
      },{
        text: '本年',
        onClick(picker) {
          const d = getSpecifiedData(new Date(), "years")
          const end = d.endDate;
          const start = d.startDate;
          picker.$emit('pick', [start, end]);
        }
      },{
        text: '上年',
        onClick(picker) {
          const d = getSpecifiedData(new Date(), "lastYears")
          const end = d.endDate;
          const start = d.startDate;
          picker.$emit('pick', [start, end]);
        }
      },{
        text: '近三年',
        onClick(picker) {
          const d = getSpecifiedData(new Date(), "lastThreeYears")
          const end = d.endDate;
          const start = d.startDate;
          picker.$emit('pick', [start, end]);
        }
      }]
    }
  }

  /** 千位分组来格式化数字
   * number 传入的数
   * bit 保留的小数位
   * sign 整数位间隔符号，默认为空格
   * gapnum 整数位每几位间隔，默认为3位一隔
   */
  export function numberFormat (number, bit = 2, sign = '', gapnum = 3) {
    if (typeof number != 'number') {
      number = number * 1
    }
    number = number.toFixed(bit) // 格式化
    let realNum = number.split('.')[0] // 整数位
    let decimal = number.split('.')[1] // 小数位
    let realNumArr = realNum.split('')

    // 把整数部分从右往左拼接，每bit位添加一个sign符号
    let str = ''
    for (let i = 1; i <= realNumArr.length; i++) {
      str = realNumArr[realNumArr.length - i] + str
      if (i % gapnum == 0) {
        str = sign + str // 每隔gapnum位前面加指定符号
      }
    }
    str = (realNum.length % gapnum == 0) ? str.substr(1) : str
    realNum = str + '.' + decimal
    return realNum
  }

  /**
 * JSON ==> FormData
 * @param {Object} data
 * @param {Boolean} isAppendNull
 */
export function toFormData(data, isAppendNull = false) {
  let formData = new FormData();
  const arr = ["pathIcon"]
  for ( let key in data ){
    if (data[key] === "" || data[key] === null || data[key] === undefined || data[key] === "null") {
      if (arr.includes(key) || isAppendNull) {
        formData.append( key, "");
      }
    } else {
      formData.append( key, data[key]);
    }
  }
  return formData;
}

/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
export function debounce(func, wait, immediate) {
  let timeout, args, context, timestamp, result

  const later = function() {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last)
    } else {
      timeout = null
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args)
        if (!timeout) context = args = null
      }
    }
  }

  return function(...args) {
    context = this
    timestamp = +new Date()
    const callNow = immediate && !timeout
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait)
    if (callNow) {
      result = func.apply(context, args)
      context = args = null
    }

    return result
  }
}

/**
 * 文件下载
 * @param {String} name 文件名
 * @param {File} file 文件
 * @param {Function} cb 回调
 * @param {String} type 文件类型
 */
export function setDownloadFilesLocally (name, file, cb, type = "application/vnd.ms-excel") {
  let fileName = name.split(".")[0]
  if (typeof window.chrome !== 'undefined') {
    // Chrome version
    const blob = new Blob([file], { type })
    const urls = window.URL.createObjectURL(blob)
    const aLink = document.createElement('a') // 生成虚拟a标签
    aLink.href = urls
    aLink.download = name
    aLink.click()
    /* document.body.removeChild(aLink)*/ // 下载完成移除元素
    window.URL.revokeObjectURL(urls) // 释放掉blob对象
  } else if (typeof window.navigator.msSaveBlob !== 'undefined') {
    // IE version
    const blob = new Blob([file], { type: 'application/force-download' })
    window.navigator.msSaveBlob(blob, fileName)
  } else {
    // Firefox version
    const file = new File([file], fileName, { type: 'application/force-download' })
    window.open(URL.createObjectURL(file))
  }
  cb()
}

/**
 * 清空对象的值
 * @param {Object} data
 */
export function clearObj (data) {
  let obj = {};
  let arr = [ "page"]
  let d = []
  for (let key in data) {
    if (!arr.includes(key)) {
      if (d.includes(key)) {
        obj[key] = null
      } else {
        obj[key] = ''
      }
    } else {
      obj[key] = data[key]
    }
  }
  return obj;
}

/**
 * @param arr - 指定数组
 * @param key - 指定key
 */
export function getArrayForKey (arr, key) {
  let arrKeys = []
  arr.forEach(item => {
    if (![null, ""].includes(item[key]))
      arrKeys.push(item[key])
  })
  return arrKeys
}

<template>
  <div id="app" style="display: flex;flex-direction: column;min-height: 100vh;">
    <!-- header -->
    <!-- <Header /> -->
    <router-view/>
    <Footer v-if="!isInAdminRoute" />
  </div>
</template>
<script>
import Footer from '@/components/footer.vue'

export default {
  data: () => ({
  }),
  mounted() {

  },
  methods: {
  },
  components: {
    Footer
  },
  computed: {
    isInAdminRoute() {
      // 检查当前路由是否以/admin开头
      return this.$route.path.startsWith('/admin');
    },
  },
}
</script>

<style lang="scss">
* {
    margin: 0;
    padding: 0;
    outline: none;
}
</style>

<style lang="scss" scoped> 
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}
</style>

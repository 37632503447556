import { toFormData } from '@/assets/utils';
import request from './index'

// 获取pdbbind版本列表
export function getVersionList (params) {
    return request({
        url: "/version/list",
        method: "GET",
        params
    })
}

// 申请报价单
export function requestInvoice (params) {
    return request({
        url: "/version/invoice",
        method: "POST",
        params
    })
}

// 发送订单到用户邮箱
export function makeOrderEmail (params) {
    return request({
        url: "/version/subscribe",
        method: "POST",
        params
    })
}

// 下单
export function makeOrder (data) {
    return request({
        url: "/pay",
        method: "POST",
        data
    })
}

// 查询订单付款状态
export function getPayStatus (params) {
    return request({
        url: "/pay-status",
        method: "GET",
        params
    })
}

// paypal支付成功传回后端信息
export function returnPaypalInfo (data) {
    return request({
        url: "/paypal-success",
        method: "POST",
        data
    })
}

// 邮件跳转回来的接口
export function emailToPay (data) {
    return request({
        url: "/version/process-to-payment",
        method: "POST",
        data
    })
}
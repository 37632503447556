<template>
    <el-dialog style="z-index: 111111;border-radius: 10px;"
        title="Welcome back"
        :visible.sync="isShow"
        width="480px"
        modal-append-to-body
        append-to-body
        center
        :show-close="true"
        :close-on-click-modal="false">
            <div slot="title" class="title">
                Welcome back
            </div>
        <el-form label-position="top" ref="ruleForm" :model="params" :rules="rules">
            <el-form-item label="" prop="email">
                <template #label>
                    <div class="d-flex space-between">
                        <span>User E-mail <span class="required">*</span></span>
                        <span class="label-desc" @click="onResetPwd('Resend activation email')">Re-send activation e-mail</span>
                    </div>
                </template>
                <el-input size="small" class="w-full input" v-model="params.email"></el-input>
            </el-form-item>
            <el-form-item label="Password" class="w-full" style="margin-bottom: 0">
                <template #label>
                    <div class="d-flex space-between">
                        <span>Password <span class="required">*</span></span>
                        <span class="label-desc" @click="onResetPwd('Forgot Your Password?')">Forget Password?</span>
                    </div>
                </template>
                <el-input
                    :key="passwordType"
                    ref="password"
                    class="input"
                    v-model="params.password"
                    size="small"
                    :type="showPassword ? 'text' : 'password'" 
                    placeholder="密码"
                    name="password"
                    tabindex="2"
                    autocomplete="on"
                    @keyup.native="checkCapslock"
                    @blur="capsTooltip = false"
                    @keyup.enter.native="onSubmit">
                    <template #append>
                        <el-button icon="el-icon-view" @click="showPassword = !showPassword"></el-button>
                    </template>
                </el-input>

            </el-form-item>
            
            <!-- <div class="d-flex space-between">
                <span>Forget Password?</span>
                <a href="/register">Register</a>
            </div> -->
            <el-button class="mt-3 w-full subbtn" style="" @click.native.prevent="onSubmit">Log in</el-button>
            <div class="bottom-desc">
                Don't have an account yet? 
                <a class="register" href="/register">Create an account</a>
            </div>
        </el-form>
        <Resetpwd ref="resetpwd" />
    </el-dialog>
</template>
<script>
/** api */
import { goLogin } from '@/assets/api/oauth'

/** compounds */
import Resetpwd from './resetpwd.vue'

export default {
    components: { Resetpwd },
    data: () => ({
        isShow: false,
        params: {
            login_type: 2,
            email: '',
            password: ''
        },
        formLabelWidth: '200px',
        rules: {},
        passwordType: "password",
        capsTooltip: false,
        showPassword: false,
    }),
    mounted() {
        this.rules = {
            email: [
                { required: true, message: '请输入Email', trigger: 'blur' },
            ],
            password: [
                { required: true, message: '请输入password', trigger: 'blur' },
            ]
        }
    },
    methods: {
        onShow() {
            this.isShow = true
        },
        onSubmit() {
            this.$refs["ruleForm"].validate((valid) => {
                if (valid) {
                    this.$loading()
                    goLogin(this.params).then(res => {
                        this.$loading().close()
                        this.onClose()
                        // 检查当前URL是否包含`?login=true`
                        let currentUrl = window.location.href;
                        let newUrl = currentUrl.replace("?login=true", "");

                        // 如果URL发生变化，更新它，但不重新加载页面
                        if (currentUrl !== newUrl) {
                            window.history.pushState({path:newUrl}, '', newUrl);
                        }

                        // 重新加载页面以反映登录状态的变化
                        window.location.reload();
                        
                    }, err => {
                        this.$loading().close()
                    })
                } else {
                    return false
                }
            })
        },
        onClose() {
            this.isShow = false
            this.$refs.ruleForm.resetFields()
        },
        checkCapslock(e) {
            const { key } = e;
            this.capsTooltip = key && key.length === 1 && key >= "A" && key <= "Z";
        },
        showPwd() {
            if (this.passwordType === "password") {
                this.passwordType = "";
            } else {
                this.passwordType = "password";
            }
            this.$nextTick(() => {
                this.$refs.password.focus();
            });
        },
        onResetPwd(title) {
            this.$refs.resetpwd.onShow(title)
        }
    }
}
</script>
<style lang="scss" scoped>
    .svg-icon {
        width: 1em;
        height: 1em;
        vertical-align: -0.15em;
        fill: currentColor;
        overflow: hidden;
    }

    .title {
        font-size: 30px;
        font-weight: bold;
        text-align: center;
        text-shadow: 0px 1px 3px rgba(0,0,0,0.75);
    }

    .label-desc {
        color: #908C8C;
        font-size: 14px;
        font-weight: normal;
        cursor: pointer;
    }

    .required {
        color: #F56C6C;
        margin-left: 3px;
        margin-top: 6px;
    }

    .subbtn {
        background-color: #1095C1;
        color: white;
        font-size: 20px;
        font-weight: bold;
        border-radius: 10px;
        border: 0px solid black;
        padding: 15px 0;
        text-shadow: 1px 1px 3px rgba(0,0,0,0.2);
    }
    .subbtn:hover {
        background-color: #1095C1;
        color: white;
        font-size: 20px;
        font-weight: bold;
        border: none;
        padding: 15px 0;
        box-shadow: 0px 0px 8px 1px rgba(0,0,0,0.6);
        text-shadow: 1px 1px 3px rgba(0,0,0,0.6);
    }
    .subbtn:focus {
        background-color: #1095C1;
        color: white;
        font-size: 20px;
        font-weight: bold;
        border: none;
        padding: 15px 0;
        box-shadow: 0px 0px 8px 1px rgba(0,0,0,0.6);
        text-shadow: 1px 1px 3px rgba(0,0,0,0.6);
    }

    .bottom-desc {
        margin-top: 10px;
        text-align: center
    }

    .register {
        color: #3BA8CC;
    }

    .register:hover {
        color: #37b2db;
        // text-shadow: 1px 1px 1px rgba(0,0,0,0.75);
    }

    ::v-deep {
        .el-form--inline .el-form-item {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
            /* text-align: right; */
        }
        .el-form--label-top .el-form-item__label {
            width: 100%;
            float: none;
            display: inline-block;
            text-align: left;
            padding: 0;
            line-height: 1;
            font-size: 16px;
            color: black;
        }

        .el-form--inline .el-form-item__content {
            width: calc(100% - 170px - 12px) !important;
            text-align: right;
            line-height: 35px;
        }
        .el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label-wrap>.el-form-item__label:before, .el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before {
            display: none;
        }

        .el-dialog { 
            border-radius: 10px;
            box-shadow: 0px 0px 8px 3px rgba(0,0,0,.6);
        }

        .input .el-input__inner {
            border-radius: 10px;
            padding: 20px;
            font-size: 16px;
        }

        .el-input-group--append .el-input__inner {
            border-radius: 10px 0 0 10px;
            padding: 20px;
            font-size: 16px;
        }
    }
</style>
<template>
    <div class="header-box" ref="header">
      <div class="header-content">
        <div class="sub-header-content1">
          <img class="logo" src="@/assets/images/header_logo.png" @click="toIndex">
          
          
        </div>

        <div class="sub-header-content2">
          <el-menu :default-active="activeIndex" class="el-menu-demo" 
           mode="horizontal"
            active-text-color="#2D5B70"
            router
            >
            <el-menu-item index="/">
              Home
            </el-menu-item>
            <el-menu-item index="/browse">
              Browse
            </el-menu-item>
            <el-menu-item index="/data/search">
              Search
            </el-menu-item>
            <el-menu-item index="/download">
              Download
            </el-menu-item>
            <el-menu-item index="/casf">
              CASF
            </el-menu-item>
            <el-submenu index="/tools">
              <template slot="title">Tools</template>
              <el-menu-item style="background-color:#007EB7" index="/comet" >COMET</el-menu-item>
              <!--el-menu-item style="background-color:#007EB7" index="/xlogp3+" >XLOGP3+</el-menu-item>
              <el-menu-item style="background-color:#007EB7" index="/xscore" >X-Score</el-menu-item-->
            </el-submenu>
            <!--el-submenu index="/tools">
              <template slot="title">Tools</template>
              <el-menu-item style="background-color:#007EB7" >Coming Soon</el-menu-item>
            </el-submenu-->
            <el-submenu index="/support">
              <template slot="title">Support</template>
              <el-menu-item style="background-color:#007EB7" index="/faq">FAQ</el-menu-item>
              <el-menu-item style="background-color:#007EB7" index="/reference">Reference</el-menu-item>
              <!--el-menu-item style="background-color:#007EB7" index="/faq">FAQ</el-menu-item-->
              <el-menu-item style="background-color:#007EB7" index="/contact-us">Contact us</el-menu-item>
            </el-submenu>

          </el-menu>
        </div>
        <div class="sub-header-content3">
          <span class="login-btn" @click="onLogin" v-if="!userInfo">Log in</span>
          <div class="user-box" style="margin-right:50px" v-else @mouseover="mouseover" @mouseout="isShow = false">
              <div class="userinfo-box" style="margin-top:21px" v-show="isShow">
                  <div class="item-user" style="margin-top: 38px;" :title="userInfo.email">{{ userInfo.email }}</div>
                  <div class="item" @click="handleSubscription(userInfo.purchased_new_version)">
                    <i class="el-icon-shopping-bag-2"></i>
                    Subscribe latest PDBbind</div>
                  <!--div class="item" @click="showSubscribeTooltip" v-if="userInfo.purchased_new_version == false">
                    <i class="el-icon-shopping-bag-2"></i>
                    Subscribe latest PDBbind</div-->  
                  <div class="item" @click="gotoAdminPanel" v-if="userInfo.role_id === 1">
                    <i class="el-icon-monitor"></i>
                    Admin Panel
                  </div>
                  <div class="item" @click="onAccount">
                    <i class="el-icon-setting"></i>
                    Edit User Profile</div>
                  <div class="item" style="border-bottom: none;" @click="logout">
                    <i class="el-icon-help"></i>
                    Logout
                  </div>
                  
              </div>
              <div class="userinfo">
                <i class="el-icon-user-solid user-avatar"></i><br />
              </div>
              <div class="version" :class="versionClass">
                {{ versionDisplay }}
              </div>
          </div>
          <span class="subscribe-btn" @click="handleSubscription">Subscribe</span>

          <!--span class="subscribe-btn" @click="showSubscribeTooltip">Subscribe</span>
          <div v-if="showTooltip" class="tooltip" :style="{ top: tooltipY + 'px', left: tooltipX + 'px' }">
            Coming soon
          </div-->
        </div>
        
      </div>
      <div>
        
      </div>
      <Login ref="login" />
      <Subscribe ref="subscribe" />
    </div>
</template>
<script>
/** api */
import { logout } from '@/assets/api/user'

import Login from './Login.vue'
import Subscribe from './subscribe.vue'

import Cookies from 'js-cookie'
export default {
    components: { Login, Subscribe },
    props: {
        activeIndex: {
            type: String,
            default: '/'
        }
    },
    data: () => ({
      userInfo: Cookies.get('userInfo') ? JSON.parse(Cookies.get('userInfo')) : null,
      isShowSubscribe: false,
      isShow: false,
      showTooltip: false, // 控制提示框是否显示
      tooltipX: 0, // 提示框的 X 坐标
      tooltipY: 0, // 提示框的 Y 坐标
    }),
    methods: {
      onLogin() {
        this.$refs.login.onShow()
      },
      logout() {
        logout().then(res => {
          Cookies.remove("userInfo")
          Cookies.remove("authorization")
          window.location.reload()
        })
      },
      toIndex() {
        // this.$router.replace(`/`)
        window.location.href = window.location.origin + '/'
      },
      onShowSubscribe() {
        //this.$refs.subscribe.onShow(this.userInfo.email)
        this.$router.push('/subscribe')
      },
      onAccount() {
        this.$router.push('/account')
      },
      gotoAdminPanel() {
        // 可以是路由导航或者是窗口重定向
        // this.$router.push('/admin');
        this.$router.push('/admin/check')
      },
      mouseover() {
        this.isShow = true
      },
      showSubscribeTooltip(event) {
        this.tooltipX = event.clientX; // 获取鼠标点击的 X 坐标
        this.tooltipY = event.clientY; // 获取鼠标点击的 Y 坐标
        this.showTooltip = true; // 显示提示框
        // 设置定时器 3 秒后隐藏提示框
        setTimeout(() => {
          this.showTooltip = false;
        }, 3000);
      },
      handleSubscription() {
        if (!this.userInfo) {
          // 用户未登录，显示登录界面
          this.onLogin();
          this.$message.error('Please log in to continue.');
        } else if (this.userInfo.purchased_new_version) {
          // 用户已登录且已购买最新数据包
          alert('Already subscribed to the latest PDBbind data package.');
        } else {
          // 用户已登录但未购买最新数据包
          this.onShowSubscribe();
        }
      },
    },
    computed: {
      versionClass() {
        // 如果版本是 'v2020'，则应用 'demo-version' 类
        if (this.userInfo && this.userInfo.version === 'PDBbind v2020') {
          return 'demo-version';
        }
        return ''; // 否则不应用额外的类
      },
    versionDisplay() {
      // 如果 userInfo 存在，根据条件返回相应的文本
      if (this.userInfo) {
        if (this.userInfo.role_id === 1) {
          return this.userInfo.role_name;
        } else if (this.userInfo.version === 'PDBbind v2020') {
          return 'demo'; // 如果版本是 'v2020'，显示 'demo'
        } else {
          return this.userInfo.version.replace('PDBbind ', '');
        }
      }
      return ''; // 如果 userInfo 不存在，返回空字符串
    }
    },
}
</script>
<style lang="scss" scoped>
.tooltip {
  position: absolute;
  padding: 5px 10px;
  background: #333;
  color: white;
  border-radius: 4px;
  pointer-events: none; /* 防止鼠标事件影响提示框 */
  transform: translate(-50%, -100%); /* 确保提示框以元素为中心 */
  white-space: nowrap; /* 防止文本换行 */
  opacity: 0.75;
  z-index: 10; /* 确保提示框位于其他元素之上 */
}
.header-box {
  position: absolute;
  width: 100%;
  background-color: rgba(0, 126, 183, 0.85);
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.header-content {
  min-width: 1700px;
  display: flex;
  align-items: center;
  justify-content: space-between; /* 子元素两端对齐 */
  padding-left: 60px; /* 或者根据需要调整，以保证logo紧贴左侧 */
  padding-right: 60px; /* 保持与左侧内边距一致 */
  height: 135px; /* 或者根据需要调整 */
}

.nav-item {
  min-width: 88px;
  height: 100px;
  position: relative;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  cursor: pointer;
  background: white;
  color: #023953;
}

.logo {
  height: 120px;
  cursor: pointer;
  margin-top: 0px; /* 上边距 */
  margin-bottom: 0; /* 下边距 */
}
.sub-header-content1 {
  // margin-left: 0px;
  // position:absolute;
  min-width: 200px;
}
.sub-header-content2 {
  margin-left: 200px;
  min-width: 770px;
  // position:absolute;
}

.sub-header-content3 {
  min-width: 295px;
  // margin-left: 1500px;
  // position:absolute;
}

nav {
  padding: 30px;
  height: 154px;
  position: sticky;
  background: #023953;

  a {
    font-weight: bold;
    color: white;

    &.router-link-exact-active {
      color: #ffffff;
      background: transparent;
    }
  }
}

::v-deep {
  .el-menu.el-menu--horizontal {
    width: 100%;
    height: 100px;
    line-height: 100px;
    border-bottom: none;
  }

  .el-menu--horizontal>.el-menu-item {
      font-size: 20px;
      height: 100px;
      line-height: 100px;
      color: white;
  }

  .el-menu--horizontal>.el-menu-item.is-active, .el-menu--horizontal>.el-submenu.is-active {
    background: transparent;
    color: #ffffff !important;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.8);
    border: none;

  }
  
  .el-menu-item.is-active::after {
    content: '';
    display: block;
    position: absolute;
    top: 85px;
    left: 50%;
    transform: translateX(-50%);
    width: 70%; /* 或者是横线的宽度 */
    height: 2px; /* 或者是横线的高度 */
    background-color: #fff; /* 横线的颜色 */
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.8);
  }
  .el-menu--horizontal>.el-submenu .el-submenu__title {
    font-size: 20px;
    height: 100px;
    line-height: 100px;
    color: white;
  }

  .el-menu {
      // border-right: solid 1px #e6e6e6;
      list-style: none;
      position: relative;
      margin: 0;
      padding-left: 0;
      background-color: transparent;
  }

  .el-menu--horizontal>.el-menu-item:not(.is-disabled):focus, .el-menu--horizontal>.el-menu-item:not(.is-disabled):hover, .el-menu--horizontal>.el-submenu .el-submenu__title:hover {
      background-color: transparent;
      color: #ffffff;
      text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.8); /* 文字阴影 */
  }

  .el-menu--horizontal>.el-menu-item:hover {
    background-color: transparent;
    color: #ffffff;
    
  }

  .el-icon-arrow-down:before {
    color: white;
  }

  .el-menu--horizontal .el-menu .el-menu-item, .el-menu--horizontal .el-menu .el-submenu__title {
    background: #0079B0;
  }
}

.login-btn {
  width: 135px;
  // height: 50px;
  border-radius: 25px;
  border: 2px solid white;
  color: white;
  // line-height: 50px;
  margin-left: 15px;
  margin-right: 30px;
  padding: 5px 20px;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
}
.login-btn:hover {  
  box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.3); /* x偏移, y偏移, 模糊半径, 颜色 */  
}
.subscribe-btn {
  width: 135px;
  border-radius: 25px;
  background: white;
  color: #007EB7;
  padding: 5px 18px;
  margin-left: 10px;
  border: 1px solid #ffffff; /* 边框颜色 */
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3); /* x偏移, y偏移, 模糊半径, 颜色 */
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
}

.subscribe-btn:hover {  
  box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.3); /* x偏移, y偏移, 模糊半径, 颜色 */  
}

.userinfo {
  margin-left:35px;
  position: relative;
  border-radius: 50%;
  border: 2px solid white;
  background: #007EB7;
  color: white;
  font-size: 12px;
  // line-height: 50px;
  // margin-left: 75px;
  padding: 5px 10px;
  cursor: pointer;
  width: 65px;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.setting-logout{
  cursor: pointer;
  .svg-icon{
    margin-right: 3px !important;
  }
}

  .user-avatar {
    
    margin-Top:-11px;
    color: white;
    font-size: 45px;
    text-shadow: 0px 0px 5px rgba(0,0,0,0.5) 
    // border: 1px solid white;
    // border-radius: 50%;
    // padding: 10px;
    // display: flex;
    // align-items: center;
    // justify-content: center;
  }

  .user-box {
    position: relative;
    display: inline-block;
    margin-right: 30px;
  }

  .userinfo-box {
    position: absolute;
    background-color: white;
    width: 200px;
    height: auto;
    margin-top:26px;
    left: -35px;
    border-radius: 5px;
    box-shadow: 0px 0px 4px rgba(0,0,0,0.5) ;
    animation: slide-down 0.2s ease forwards;
  }

  .item {
    font-size: 12px;
    font-weight: bold;
    padding: 8px 10px;
    text-align: left;
    cursor: pointer;
    border-bottom: 1px solid #AFABAB;
  }
  .item-user {
    white-space: nowrap; /* 不换行 */
    overflow: hidden; /* 超出部分隐藏 */
    text-overflow: ellipsis; /* 显示省略号 */
    max-width: 200px; /* 设置一个合理的最大宽度，确保布局正常 */


    font-size: 12px;
    font-weight: bold;
    padding: 8px 10px;
    text-align: left;
    cursor: pointer;
    border-bottom: 1px solid #AFABAB;
  }

  .item i {
    font-size: 14px;
  }

  .item:hover {
    background: #006FBE;
    color: white;
  }

  .version {
    position: absolute;
    top: 32px;
    // right: 12.5px;
    color: #007EB7;
    font-size: 12px;
    font-weight:bold;
    font-family:Futura;
    background: white;
    border-radius: 10px;
    padding-top:1px;
    padding-bottom:1px;
    left: 43px;
    right: 8px;
    box-shadow:  0px 0px 4px rgba(0,0,0,0.5) ;
    text-shadow: 1px 1px 2px rgba(0,0,0,0.2) ;
    
  }

  .demo-version {
    position: absolute;
    top: 32px;
    // right: 12.5px;
    color: #007EB7;
    font-size: 12px;
    font-weight:bold;
    font-family:Futura;
    background: white;
    border-radius: 10px;
    padding-top:1px;
    padding-bottom:1px;
    left: 43px;
    right: 8px;
    box-shadow:  0px 0px 4px rgba(0,0,0,0.5) ;
    text-shadow: 1px 1px 2px rgba(0,0,0,0.1) ;
  }


</style>